<template>
    <el-dialog
        :model-value="show"
        :before-close="onClose"
        :close-on-click-modal="false"
        custom-class="common-edit"
        title="新增安装"
        width="600px"
    >
        <el-form ref="dataFormRef" :label-width="120" :model="dataForm" :rules="dataRules" label-position="left">
            <el-form-item label="选择施工方：" prop="build_id">
                <el-select v-model="dataForm.build_id" filterable placeholder="请选择施工方">
                    <el-option
                        v-for="item in installerOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button @click="onClose">取消</el-button>
            <el-button type="primary" @click="onSubmit">提交</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, watch } from "vue";
import { ElMessage } from "element-plus";
import { ADD_GOODS_INSTALL, GET_GOODS_INSTALLER_LIST } from "@/api/goods";

let props = defineProps({
    show: {
        type: Boolean,
        default: false
    },
    id: { type: [String, Number] },
    detail: { type: Object }
});

let emit = defineEmits(["update:show", "update"]);

watch(
    () => ({ id: props.id, show: props.show }),
    (newVal) => {
        if (newVal.show) getOptions();
    },
    { deep: true }
);

// 获取选项
let installerOptions = ref([]);

let getOptions = () => {
    GET_GOODS_INSTALLER_LIST({}).then((res) => {
        installerOptions.value = res.data.data;
    });
};

// 表单数据
let dataForm = ref({
    build_id: null
});

let dataFormRef = ref();

let dataRules = {
    build_id: [{ required: true, message: "请选择施工方！", trigger: "change" }]
};

// 关闭
let onClose = () => {
    if (dataFormRef.value) dataFormRef.value.resetFields();
    emit("update");
    emit("update:show", false);
};

// 提交
let onSubmit = () => {
    dataFormRef.value.validate((valid) => {
        if (valid) {
            ADD_GOODS_INSTALL({
                ...dataForm.value,
                id: props.id
            }).then((res) => {
                if (res.data.code === 200) {
                    onClose();
                    ElMessage.success("新增成功");
                }
            });
        }
    });
};
</script>
