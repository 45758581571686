<template>
    <gk-searchbar
        v-model:form="searchForm"
        :config="searchConfig"
        :fold-display-number="3"
        :foldable="true"
        :options="searchOptions"
        @reset="getDataList"
        @search="getDataList"
    />
    <div class="common-list">
        <div class="button-wapper"></div>
        <div class="table-wrapper">
            <el-table :data="dataList" border stripe @selection-change="changeSelectionIds">
                <el-table-column type="selection" width="55" />
                <el-table-column label="ID" prop="id" width="100" />
                <el-table-column label="订单号" prop="order.order_sn" />
                <el-table-column label="产品信息">
                    <template #default="scope">
                        <span v-for="(item, index) in scope.row.order.information">
                            {{ `${Object.keys(item)[0]}:${Object.values(item)[0]}` }}
                            {{ index !== scope.row.order.information.length - 1 ? "、" : "" }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="电杆数量" prop="order.number" width="100" />
                <el-table-column label="安装状态">
                    <template #default="scope">
                        <template v-if="scope.row.status === 0">待安装</template>
                        <template v-if="scope.row.status === 1">进行中</template>
                        <template v-if="scope.row.status === 2">已安装</template>
                    </template>
                </el-table-column>
                <el-table-column label="完成时间">
                    <template #default="scope">
                        {{ scope.row.status_at ? scope.row.status_at : "/" }}
                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="order.remark" />
                <el-table-column label="操作" width="150">
                    <template #default="scope">
                        <el-button plain size="mini" type="primary" @click="openDetailDialog(scope.row.id)">
                            详情
                        </el-button>
                        <el-button
                            :disabled="scope.row.status !== 0"
                            plain
                            size="mini"
                            type="success"
                            @click="openEditDialog(scope.row)"
                        >
                            安装
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-wrapper">
            <gk-pagination
                v-model:currentPage="dataPagination.current"
                v-model:pageSize="dataPagination.size"
                :total="dataPagination.total"
                @change="getDataList"
            />
        </div>
    </div>
    <goods-install-detail :id="detailDialog.id" v-model:show="detailDialog.show" />
    <goods-install-edit :id="editDialog.id" v-model:show="editDialog.show" @update="getDataList" />
</template>

<script setup>
import { onMounted, ref } from "vue";
import useCommonList from "../../../../hooks/useCommonList";
import GoodsInstallDetail from "./GoodsInstallDetail.vue";
import GoodsInstallEdit from "./GoodsInstallEdit.vue";
import { GET_GOODS_INSTALL_LIST } from "@/api/goods";

let { dataPagination, dataList, selectionIds, changeSelectionIds } = useCommonList();

onMounted(() => {
    getDataList();
});

// 搜索项
let searchConfig = ref([{ prop: "status", type: "select", label: "安装状态" }]);

let searchOptions = ref({
    status: [
        { label: "待安装", value: 0 },
        { label: "进行中", value: 1 },
        { label: "已完成", value: 2 }
    ]
});

let searchForm = ref({
    status: 0
});

// 获取数据
let getDataList = () => {
    GET_GOODS_INSTALL_LIST({
        ...searchForm.value,
        page: dataPagination.value.current,
        limit: dataPagination.value.size
    }).then((res) => {
        dataList.value = res.data.data.list;
        dataPagination.value.total = res.data.data.mate.total;
        console.log(res.data.data);
    });
};

// 增删改
let editDialog = ref({
    show: false,
    id: null
});

let openEditDialog = (item = null) => {
    editDialog.value = {
        show: true,
        id: item ? item.id : null
    };
};

// 查看详情
let detailDialog = ref({
    show: false,
    id: null
});

let openDetailDialog = (id) => {
    detailDialog.value = {
        show: true,
        id: id
    };
};
</script>
